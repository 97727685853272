import React, { useState, useEffect, useRef } from "react";
import DeleteAccountPopup from "../Popup/DeleteAccountPopup";
import EditAccountPopup from "../Popup/EditAccountPopup";
import LoadingButton from "../Loading button/LoadingButton";
import DeleteAllAccountsPopup from "../Popup/DeleteAllAccountsPopup";
import "./ManageAccountsDetailsStyle.css";

const ManageAccountsDetails = () => {
  let [contactNumber, setContactNumber] = useState();
  let [errorMessage, setErrorMessage] = useState({});
  let [isNewCustomer, setIsNewCustomer] = useState();
  let [isSubmit, setIsSubmit] = useState(false);
  let [showTable, setShowTable] = useState(false);
  let [responseGetAccounts, setResponseGetAccounts] = useState({
    accounts: [],
  });
  let [responseDeleteAccount, setResponseDeleteAccount] = useState("");
  let [isResponseLoading, setIsResponseLoading] = useState(false);

  const handleGetAccountsRequest = (e) => {
    e.preventDefault();
    setIsNewCustomer("");
    setShowTable(false);
    setErrorMessage(validate(contactNumber));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(errorMessage).length === 0 && isSubmit) {
      setIsResponseLoading(true);
      getAccounts();
    }
  }, [errorMessage]);

  const validate = (contactNumber) => {
    const errors = {};
    if (!/^([1-9]{1}[0-9]{9})$/.test(contactNumber))
      errors.contactNumber =
        "Mobile Number should be 10-digit and first digit can't be 0!";
    return errors;
  };

  const getFipName = (fipId) => {
    if (fipId === "BARB0KIMXXX") return "Finvu Bank";
    else if (fipId === "dhanagarbank") return "Dhanagar Bank";
    else if (fipId === "fip@finsure") return "Finsure";
    else if (fipId === "fip@finsuregeneral") return "Finsure General";
    else if (fipId === "fip@inspol") return "FIP insurance policies";
    else if (fipId === "fip@finrepo") return "Finrepo";
    else if (fipId === "fip@finvugst") return "Finvu GSTN";
  };

  const generateUniquePan = (mobile) => {
    let panBuilder = '';

    // Get corresponding alphabets for the first 5 digits of the mobile number
    for (let i = 0; i < 5; i++) {
      const alphabet = String.fromCharCode(parseInt(mobile.charAt(i)) - 0 + 'A'.charCodeAt(0));
      panBuilder += alphabet;
    }

    // Add the next 4 digits of the mobile number (index 5-8)
    panBuilder += mobile.substring(5, 9);

    // Get the corresponding alphabet for the last digit of the mobile number
    const lastAlphabet = String.fromCharCode(parseInt(mobile.charAt(9)) - 0 + 'A'.charCodeAt(0));
    panBuilder += lastAlphabet;

    return panBuilder;
  }

  const idAccountRef = useRef();
  const accountObjRef = useRef({});
  const idFipId = useRef();

  const [deletePopup, setDeletePopup] = useState({
    message: "",
    isLoading: false,
    account: {},
  });
  const handleDeletePopup = (message, isLoading, account) => {
    setDeletePopup({
      message,
      isLoading,
      account,
    });
  };

  const [editPopup, setEditPopup] = useState({
    message: "",
    isLoading: false,
    account: {},
  });

  const handleEditPopup = (message, isLoading, account) => {
    setEditPopup({
      message,
      isLoading,
      account
    });
  };

  const [deleteAllPopup, setDeleteAllPopup] = useState({
    message: "",
    isLoading: false,
    fipId: "",
  });

  const handleDeleteAllPopup = (message, isLoading, fipId) => {
    setDeleteAllPopup({
      message,
      isLoading,
      fipId,
    });
  };

  const handleDeleteAllAccounts = (fipId) => {
    handleDeleteAllPopup(
      "All accounts for selected FIP will be deleted!",
      true,
      fipId
    );
    idFipId.current = fipId;
  };

  const handleDeleteAccount = (account, index) => {
    handleDeletePopup("Following account will be deleted!", true, account);
    idAccountRef.current = index;
    accountObjRef.current = account;
  };

  const handleEditAccount = (account) => {
    handleEditPopup("Account Data", true, account);
  };

  const deleteAllConfirm = (choose) => {
    if (choose) {
      deleteAllAccountByFip(contactNumber, idFipId.current);
      handleDeleteAllPopup("", false);
    } else {
      handleDeleteAllPopup("", false);
    }
  };

  const deleteConfirm = (choose) => {
    if (choose) {
      deleteAccount(accountObjRef.current, idAccountRef.current);
      handleDeletePopup("", false);
    } else {
      handleDeletePopup("", false);
    }
  };

  const editConfirm = () => {
    handleEditPopup("", false);
  };

  async function getAccounts() {
    const hostname = window.location.hostname;
    await fetch(`https://${hostname}/ConnectHub/AccountManagement/Accounts/${contactNumber}`,{
        method: "GET"
      }
    )
      .then((response) => {
        setIsResponseLoading(false);
        return response.json();
      })
      .then((data) => {
        setResponseGetAccounts(
          (responseGetAccounts.accounts = data["accounts"])
        );
        if (responseGetAccounts.accounts.length === 0) {
          setIsNewCustomer(
            "No account present for " +
              contactNumber +
              "! Please create an account with Simulator Bank"
          );
        } else setShowTable(true);
      });
  }
  
  async function deleteAccount(account, index) {
    const hostname = window.location.hostname;
    await fetch(`https://${hostname}/ConnectHub/AccountManagement/Account/${account.fipId}/${account.accountRefNo}`, {
      method: "DELETE"
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        setResponseDeleteAccount(
          (responseDeleteAccount = JSON.stringify(data))
        );
      });
    if (responseDeleteAccount.includes("deleted successfully!")) {
      const updatedAccounts = responseGetAccounts.filter((elem, id) => {
        return id !== index;
      });
      setResponseGetAccounts((responseGetAccounts.accounts = updatedAccounts));
    }
  }

  async function deleteAllAccountByFip(contactNumber, fipId) {
    const hostname = window.location.hostname;
    let responseData;
    await fetch(`https://${hostname}/ConnectHub/AccountManagement/Accounts/${fipId}/${contactNumber}`,{
        method: "DELETE"
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        responseData = data;
      });
    if (!responseData.includes("No account exists")) {
      setShowTable(false);
      getAccounts();
      setShowTable(true);
    }
  }

  return (
    <>
      <div>
        <label htmlFor="contactNumber">
          <b>Mobile Number:</b>
        </label>
        <input
          type="text"
          placeholder="Mobile Number"
          name="contactNumber"
          id="contactNumber"
          onChange={(e) => setContactNumber(e.target.value)}
        />
        <span>
          <LoadingButton
            btnTitle="Search Accounts"
            isLoading={isResponseLoading}
            onClick={(e) => {
              handleGetAccountsRequest(e);
            }}
          />
        </span>
      </div>
      {!/^([1-9]{1}[0-9]{9})$/.test(contactNumber) && (
        <span style={{ fontSize: 14, color: "red" }}>
          {errorMessage.contactNumber}
        </span>
      )}
      <hr />
      <div id="manageAccounts">
        Don't have any account? <a href="/">Create Account</a>
      </div>
      <span style={{ fontSize: 14, color: "red" }}>{isNewCustomer}</span>
      {isNewCustomer === "" && showTable && (
        <div>
          <div style={{ margin: "20px" }}>

            <h6>
              Delete all Finsure accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("fip@finsure")}
              ></i>
            </h6>
            
            <h6>
              Delete all Finrepo accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("fip@finrepo")}
              ></i>
            </h6>
            
            <h6>
              Delete all Finvu Bank accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("BARB0KIMXXX")}
              ></i>
            </h6>
            
            <h6>
              Delete all Finvu GSTN accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("fip@finvugst")}
              ></i>
            </h6>

            <h6>
              Delete all Finsure General accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("fip@finsuregeneral")}
              ></i>
            </h6>

            <h6>
              Delete all Insurance accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("fip@inspol")}
              ></i>
            </h6>

            <h6>
              Delete all Life Insurance FIP accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("fip@life")}
              ></i>
            </h6>

            <h6>
              Delete all General Insurance FIP accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("fip@general")}
              ></i>
            </h6>

            <h6>
              Delete all Dhanagar Bank accounts:{" "}
              <i
                title="Delete All Accounts"
                className="fa-solid fa-trash-can"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteAllAccounts("dhanagarbank")}
              ></i>
            </h6>

          </div>
          <div>
            <label htmlFor="pan">
              <b>Registered PAN:</b>
            </label>
            <input
              type="text"
              name="pan"
              id="pan"
              value={generateUniquePan(contactNumber)}
              readOnly
            />
            <p style={{ fontSize: 14, color: "red" }}>Use this PAN for discovering account from Finvu AA</p>
          </div>
          <div className="scroll-container">
            <table className="account-list">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Fip Name</th>
                  <th>Account Number</th>
                  <th>FI Type</th>
                  <th>Account Type</th>
                  <th>AA Id</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {responseGetAccounts.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{getFipName(item.fipId)}</td>
                      <td>{item.accountNo}</td>
                      <td>{item.fiType==="TERM-DEPOSIT"?"TERM_DEPOSIT":item.fiType}</td>
                      <td>{item.accountType}</td>
                      <td>{(item.custAaId).length!==0 ? (item.custAaId).map((x, j) => {
                        return (
                          <ul key={j} style={{ margin: "0", padding: "0" }}>{x}</ul>
                        )
                      }) : "-"}</td>
                      <td>
                        
                          <i
                            title="Edit Account"
                            className="far fa-edit"
                            style={{
                              color: "blue",
                              cursor: "pointer",
                              paddingRight: "15px",
                            }}
                            onClick={() => handleEditAccount(item)}
                          ></i>
                        <i
                          title="Delete Account"
                          className="fa-solid fa-trash-can"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleDeleteAccount(item, i)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {deletePopup.isLoading && (
        <DeleteAccountPopup
          fipId={deletePopup.account.fipId}
          accountNo={deletePopup.account.accountNo}
          accountRefNo={deletePopup.account.accountRefNo}
          fiType={deletePopup.account.fiType}
          accountType={deletePopup.account.accountType}
          onPopup={deleteConfirm}
          message={deletePopup.message}
        />
      )}
      {editPopup.isLoading && (
        <div>
          <EditAccountPopup
            account={editPopup.account} 
            onPopup={editConfirm}
            message={editPopup.message}
          />
        </div>
      )}
      {deleteAllPopup.isLoading && (
        <DeleteAllAccountsPopup
          fipId={deleteAllPopup.fipId}
          onPopup={deleteAllConfirm}
          message={deleteAllPopup.message}
        />
      )}
    </>
  );
};

export default ManageAccountsDetails;
