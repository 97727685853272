import React from "react";
import "./DeleteAllAccountsPopupStyle.css";

function DeleteAllAccountsPopup({
  fipId,
  onPopup,
  message
}) {
  const getFipName = (fipId) => {
    if (fipId === "BARB0KIMXXX") return "Finvu Bank";
    else if (fipId === "dhanagarbank") return "Dhanagar Bank";
    else if (fipId === "fip@finsure") return "Finsure";
    else if (fipId === "fip@finsuregeneral") return "Finsure General";
    else if (fipId === "fip@inspol") return "FIP insurance policies";
    else if (fipId === "fip@general") return "General Insurance FIP";
    else if (fipId === "fip@life") return "Life Insurance FIP";
    else if (fipId === "fip@finrepo") return "Finrepo";
    else if (fipId === "fip@finvugst") return "Finvu GSTN";
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.6)",
      }}
      onClick={() => onPopup(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          background: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <h5 style={{ color: "red" }}>{message}</h5>
        <br />
        <div>
          <h6>Fip Name: {getFipName(fipId)}</h6>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <button
              type="submit"
              className="delete-btn"
              onClick={() => onPopup(true)}
            >
              Delete
            </button>
          </span>
          <span>
            <button
              type="submit"
              className="cancel-btn"
              onClick={() => onPopup(false)}
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default DeleteAllAccountsPopup;
